import React, { useEffect, useState } from 'react';
import './App.css';

function MazeGrid({ width = 23, height = 23 }) {
  let initialMaze = [
    ['wall', 'wall', 'wall', 'wall', 'wall'],
    ['start', 'path', 'path', 'path', 'wall'],
    ['wall', 'path', 'wall', 'path', 'wall'],
    ['wall', 'path', 'path', 'path', 'wall'],
    ['wall', 'wall', 'wall', 'wall', 'end'],
  ];
  const [maze, setMaze] = useState(initialMaze);
  const [timeoutIds, setTimeoutIds] = useState([]);

  useEffect(() => {
    generateMaze(width, height);
  }, [width, height]);

  function bfs(startNode) {
    let queue = [startNode];
    let visited = new Set(`${startNode[1]}, ${startNode[0]}`);

    function visitCell([x, y]) {
      setMaze((prevMaze) =>
        prevMaze.map((row, rowIndex) =>
          row.map((cell, cellIndex) => {
            if (cellIndex === x && rowIndex === y) {
              return cell === 'end' ? 'end' : 'visited';
            }
            return cell;
          })
        )
      );

      if (maze[y][x] === 'end') {
        console.log('Found the path');
        return true;
      }
      return false;
    }

    function step() {
      if (queue.length === 0) {
        return;
      }
      const [x, y] = queue.shift();

      const dirs = [
        [0, 1],
        [1, 0],
        [0, -1],
        [-1, 0],
      ];

      for (const [dx, dy] of dirs) {
        let nx = x + dx;
        let ny = y + dy;

        if (nx >= 0 && ny >= 0 && nx < width && ny < height && !visited.has(`${nx},${ny}`)) {
          visited.add(`${nx},${ny}`);

          if (maze[ny][nx] === 'path' || maze[ny][nx] === 'end') {
            if (visitCell([nx, ny])) {
              return true;
            }
            queue.push([nx, ny]);
          }
        }
      }

      const timeoutId = setTimeout(step, 100);
      setTimeoutIds((prevTimeoutIds) => [...prevTimeoutIds, timeoutId]);
    }

    step();
    return false;
  }

  function dfs(startNode) {
    let stack = [startNode];
    let visited = new Set(`${startNode[1]}, ${startNode[0]}`);

    function visitCell([x, y]) {
      setMaze((prevMaze) =>
        prevMaze.map((row, rowIndex) =>
          row.map((cell, cellIndex) => {
            if (cellIndex === x && rowIndex === y) {
              return cell === 'end' ? 'end' : 'visited';
            }
            return cell;
          })
        )
      );

      if (maze[y][x] === 'end') {
        console.log('Found the path');
        return true;
      }
      return false;
    }

    function step() {
      if (stack.length === 0) {
        return;
      }
      const [x, y] = stack.pop();

      const dirs = [
        [0, 1],
        [1, 0],
        [0, -1],
        [-1, 0],
      ];

      for (const [dx, dy] of dirs) {
        let nx = x + dx;
        let ny = y + dy;

        if (nx >= 0 && ny >= 0 && nx < width && ny < height && !visited.has(`${nx},${ny}`)) {
          visited.add(`${nx},${ny}`);

          if (maze[ny][nx] === 'path' || maze[ny][nx] === 'end') {
            if (visitCell([nx, ny])) {
              return true;
            }
            stack.push([nx, ny]);
          }
        }
      }
      const timeoutId = setTimeout(step, 100);
      setTimeoutIds((prevTimeoutIds) => [...prevTimeoutIds, timeoutId]);
    }

    step();
    return false;
  }

  function generateMaze(height, width) {
    let matrix = [];

    for (let i = 0; i < height; i++) {
      let row = [];
      for (let j = 0; j < width; j++) {
        row.push('wall');
      }
      matrix.push(row);
    }

    console.log('matrix', matrix);

    const dirs = [
      [0, 1],
      [1, 0],
      [0, -1],
      [-1, 0],
    ];

    function isCellValid(x, y) {
      return y >= 0 && x >= 0 && y < height && x < width && matrix[y][x] === 'wall';
    }

    function carvePath(x, y) {
      matrix[y][x] = 'path';

      const directions = dirs.sort(() => Math.random() - 0.5);

      for (let [dx, dy] of directions) {
        let nx = x + dx * 2;
        let ny = y + dy * 2;

        if (isCellValid(nx, ny)) {
          matrix[y + dy][x + dx] = 'path';
          carvePath(nx, ny);
        }
      }
    }

    carvePath(1, 1);

    matrix[1][0] = 'start';
    matrix[height - 2][width - 1] = 'end';
    // setWidth(matrix[0].length);
    // setHeight(matrix.length);

    setMaze(matrix);
  }

  function refreshMaze() {
    timeoutIds.forEach(clearTimeout);
    setTimeoutIds([]);
    generateMaze(width, height);
  }

  return (
    <div className="maze-grid">
      <div className="controls">
        <button className="maze-button" onClick={() => refreshMaze()}>
          Refresh Maze
        </button>
        <button className="maze-button" onClick={() => bfs([1, 0])}>
          Breadth-First Search
        </button>
        <button className="maze-button" onClick={() => dfs([1, 0])}>
          Depth-First Search
        </button>
      </div>
      <div className="maze">
        {maze.map((row, rowIndex) => {
          return (
            <div className="row">
              {row.map((cell, cellIndex) => {
                console.log('cell', cellIndex);
                return <div className={`ceil ${cell}`}></div>;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MazeGrid;
